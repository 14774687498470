import { createContext } from 'react'

interface UserContextType {
  user: any
  setUser: any
}

const UserContext = createContext<UserContextType>({
	user: {},
	setUser: {}
})

export default UserContext
