// Next
import Link from 'next/link'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { createClient } from 'graphqurl'

// Bootstrap
import {
	Container,
	Row,
	Col,
	Image,
	Modal,
	Button,
	NavLink,
	Carousel
} from 'react-bootstrap'

// Style
import styles from '../styles/Footer.module.scss'

// Other
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDirections } from '@fortawesome/free-solid-svg-icons'
import FindjobsButton from './common/button/FindjobsButton'

interface Banner {
	id: any
	banner: any
	link: any
}

const Footer = () => {
	const { t } = useTranslation()
	const [showModal, setShowModal] = useState(false)

	const [banners, setBanners] = useState<Banner[]>([])
	
	const router = useRouter()

	const jobPosterPages = ['/job-posters', '/job-posters/[id]', '/job-basket/saved/[uuid]']

	useEffect(() => {
		if (jobPosterPages.includes(router.pathname)) {
			const client = createClient({
				endpoint: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT as string
			})

			client.query({
				query: `
				query {
					Event(where: {
						startDate: {_lte: "now()"}
						endDate: {_gte: "now()"}
					} order_by: {startDate: desc}) {
						id banner
					}
				}
				`
			})
				.then(res => {
					const { data: { Event } } = res

					Event.push({
						banner: '/img/banner_portal/NTUC_Teacher.png',
						id: null,
						link: 'https://forms.gle/m2LE7W2QJ8E4eFAG8'
					})
					Event.push({
						banner: '/img/findjobs-google.png',
						id: null,
						link: 'https://docs.google.com/forms/d/e/1FAIpQLScIk07GJXEhJjThqUGxkIpjy598oh1v360QAL3DST70PJAUaA/viewform?usp=sf_link',
					})
					Event.push({
						banner: '/img/lmunited.png',
						id: null,
						link: 'https://lmunitedjobs.com.sg/en/jobs'
					})
					Event.push({
						banner: '/img/train_and_match.jpeg',
						id: null,
						link: 'https://trainandmatch.findjobs.com.sg'
					})

					setBanners(Event)
				})
				.catch(err => {
					console.error(err)
				})
		}
	}, [])

	return (
		<>
			{(process.env.NEXT_PUBLIC_SITE_NAME as string === 'findjobs' && !jobPosterPages.includes(router.pathname)) &&
			<div>
				<div className={styles.footer}>
					<div className={styles.footerbg}>
						<Container>
							<Row className='pt-5'>
								<Col className='mb-4 auto' xs={12} sm={7} md={9}>
									<Image src='/img/findjobs-logo.png' alt='' width='150' className='mb-5' />
									<h1 className={`${styles.subtitle} text-white`}>
									A Tech Platform that Champions Inclusive Employment
									</h1>
								</Col>
								<Col className='mb-4 float-right'>
									<h1 className={`${styles.subtitle} text-white mb-4`}>
										{t('footer.resource')}
									</h1>
									<Link href='/app'>
										<p className='text-white' style={{ cursor: 'pointer' }}>
											{t('footer.download_app')}
										</p>
									</Link>
									<Link href='/jobs'>
										<p className='text-white' style={{ cursor: 'pointer' }}>
											{t('footer.browse_jobs')}
										</p>
									</Link>
									<Link href='/employer'>
										<p className='text-white' style={{ cursor: 'pointer' }}>
											{t('footer.post_job')}
										</p>
									</Link>
									<Link href='about-us'>
										<p className='text-white' style={{ cursor: 'pointer' }}>
											{t('footer.about_us')}
										</p>
									</Link>
									<Link href='terms-of-use'>
										<p className='text-white' style={{ cursor: 'pointer' }}>
											{t('footer.terms_use')}
										</p>
									</Link>
									<Link href='privacy-policy'>
										<p className='text-white' style={{ cursor: 'pointer' }}>
											{t('footer.privacy_policy')}
										</p>
									</Link>
								</Col>
							</Row>
							<hr style={{ border: '2px solid #FFFFFF' }} className='my-4' />
							<Row className='py-3 px-md-5 text-white d-flex flex-column flex-md-row'>
								<Col className='mb-4'>
									<Row style={{ height: '100%' }}>
										<Col xs={2} className='d-flex justify-content-center align-items-center'>
											<Image src='/img/landing_page/icons/phone.png' alt='' width='30px' className={`${styles.negativeImage} mb-3`} />
										</Col>
										<Col className='d-flex justify-content-start align-items-center'>
											<Link href="tel:+6569049612">
												<p className={`${styles.text} ${styles.footerLink}`}>+65 6916 4240</p>
											</Link>
										</Col>
									</Row>
								</Col>
								<Col className='mb-4'>
									<Row style={{ height: '100%' }}>
										<Col xs={2} className='d-flex justify-content-center align-items-center'>
											<Image src='/img/landing_page/icons/Envelope.png' alt='' width='30px' className={`${styles.negativeImage} mb-3`} />
										</Col>
										<Col className='d-flex justify-content-start align-items-center'>
											<Link href="mailto:sales@findjobs.com.sg">
												<p className={`${styles.text} ${styles.footerLink}`}>sales@findjobs.com.sg</p>
											</Link>
										</Col>
									</Row>
								</Col>
								<Col className='mb-4'>
									<Row style={{ height: '100%' }}>
										<Col xs={2} className='d-flex justify-content-center align-items-center'>
											<Image src='/img/landing_page/icons/location.png' alt='' width='30px' className={`${styles.negativeImage} mb-3`} />
										</Col>
										<Col className='d-flex flex-column justify-content-center align-items-start'>
											<p className={`${styles.text} mb-0`}>75 Ayer Rajah Crescent,<br />#01-04 Singapore 139953</p>
											<Button onClick={() => setShowModal(true)} variant='link' className='text-decoration-none mt-0 pt-0 px-0'>{t('footer.view_map')}</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</div>
					<div className='text-center'>
						<div className='text-center mb-4 mt-4 pt-3'>&copy; {new Date().getFullYear()} Findjobs Pte Ltd. All Rights Reserved.</div>
						<div className='d-flex flex-column flex-md-row align-items-center justify-content-center'>
							<p className='mx-4'>{t('footer.follow_us_on')}</p>
							<div className='d-flex align-items-center justify-content-center mb-2'>
								<Link href='https://www.facebook.com/findjobs.com.sg/' passHref>
									<a target='_blank'>
										<Image src='/img/landing_page/icons/Facebook.png' alt='Facebook' width='40px' className='mx-2 mx-md-3 mb-3' style={{ cursor: 'pointer' }} />
									</a>
								</Link>
								<Link href='https://www.instagram.com/findjobs.com.sg/' passHref>
									<a target='_blank'>
										<Image src='/img/landing_page/icons/Instagram.png' alt='Instagram' width='40px' className='mx-2 mx-md-3 mb-3' style={{ cursor: 'pointer' }} />
									</a>
								</Link>
								<Link href='https://sg.linkedin.com/company/findjobs-pte-ltd' passHref>
									<a target='_blank'>
										<Image src='/img/landing_page/icons/Linkedin.png' alt='Linkedin' width='40px' className='mx-2 mx-md-3 mb-3' style={{ cursor: 'pointer' }} />
									</a>
								</Link>
								<Link href='https://www.tiktok.com/@findjobssg' passHref>
									<a target='_blank'>
										<Image src='/img/landing_page/icons/Tiktok.png' roundedCircle alt='Tiktok' width='33px' className='mx-2 mx-md-3 mb-3 p-2' style={{ cursor: 'pointer', background: 'black' }} />
									</a>
								</Link>
							</div>
						</div>
					</div>
				</div>
				<Modal size='lg' show={showModal} onHide={() => { setShowModal(false) }} dialogClassName={styles.modalBorder}>
					<Modal.Header closeButton style={{ border: 'none', paddingBottom: '0' }} />
					<Modal.Title><h4 className='text-center font-weight-700'>
						{t('footer.our_location')}
					</h4></Modal.Title>
					<Modal.Body>
						<div className={styles.viewMap}>
							<iframe src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7977.591048716865!2d103.786321!3d1.297373!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5bc0ec89fb94563a!2sFindjobs%20Pte%20Ltd!5e0!3m2!1sen!2ssg!4v1631175371352!5m2!1sen!2ssg' width='100%' height='450' style={{ border: '0' }} allowFullScreen={false} loading='lazy' />
						</div>
						<div className='text-center mt-4 mb-3'>
							<Link href='https://goo.gl/maps/xW8mfeimTZrgxR5f7' passHref>
								<NavLink>
									<FindjobsButton text={
										<>
											<FontAwesomeIcon icon={faDirections} />
											{t('footer.direction')}
										</>
									} type={undefined} />
								</NavLink>
							</Link>
						</div>
					</Modal.Body>
				</Modal>
			</div>}
			{(process.env.NEXT_PUBLIC_SITE_NAME as string === 'findjobs' && jobPosterPages.includes(router.pathname)) && (
				<>
					<div className={`${styles.hero} ${styles.hfull} d-flex justify-content-center align-items-center`}>
						<Container className={styles.hero__content}>
							<Row className='d-flex flex-column flex-md-row justify-content-center align-items-center'>
								{/* Image on the left */}
								<Col xs={12} md={6} className='d-flex flex-column justify-content-center align-items-center mt-5'>
									<div>
										<Image src='/img/landing_page/hero.png' alt='' className={styles.bannerPhone} />
									</div>
								</Col>
								{/* QR Code and Buttons to respective app stores */}
								<Col className='d-flex flex-column justify-content-center align-items-center align-items-md-start'>
									<h1 className={`${styles.title} text-white`}>
								Download Findjobs Today!
									</h1>
									<h3 className={`${styles.subtitles} text-white mb-3`}>
								Discover jobs near you
									</h3>
									<div className='my-2 my-md-5 d-flex flex-column flex-sm-row align-items-center'>
										<div>
											<Image src='/img/app_download/qr.png' alt='qr' className={styles.qr} />
										</div>
										<div className={styles.dividerVertical}/>
										<div className='d-flex flex-column'>
											<Link href='https://apps.apple.com/sg/app/findjobs-find-jobs-easily/id1627650264'>
												<a target="_blank">
													<Image src='/img/store/AppStore.png' alt='AppStore' width='100%' className={`my-2 ${styles.store}`} />
												</a>
											</Link>
											<Link href='https://play.google.com/store/apps/details?id=com.findjobs.findjobsapp'>
												<a target="_blank">
													<Image src='/img/store/PlayStore.png' alt='PlayStore' width='100%' className={`my-2 ${styles.store}`} />
												</a>
											</Link>
											<Link href='https://appgallery.huawei.com/app/C106400895'>
												<a target="_blank">
													<Image src='/img/store/AppGallery.png' alt='AppGallery' width='100%' className={`my-2 ${styles.store}`} />
												</a>
											</Link>
										</div>
									</div>
								</Col>

								<h2 className={styles.hr}>
									<span>Or</span>
								</h2>

								<Row className='mb-5'>
									<Col className='text-center'>
										<h1 className='text-white fw-bolder'>Visit our job portal now!</h1>
										<p className='text-white'>Discover your desired job</p>
										<Link href='/browse-jobs' passHref>
											<Button className={`text-white ${styles.portalButton}`}>Visit our job portal</Button>
										</Link>
									</Col>
								</Row>
							</Row>
							
						</Container>
					</div>
					{banners.length > 0 && (
						<div className='pt-5 pb-5'>
							<Carousel className={`${styles.carousel} mx-auto`} variant='dark'>
								{banners.map((ele, index) => {
									if (ele.id !== null) {
										return (
											<Carousel.Item key={index}>
												<a
													target='_blank' rel='noreferrer'
													href={`/event/${ele.id as string}`}
												>
													<Image src={ele.banner} alt='' className='w-100 rounded' />
												</a>
											</Carousel.Item>
										)
									} else return (
										<Carousel.Item key={index}>
											<a
												target='_blank' rel='noreferrer'
												href={ele.link}
											>
												<Image src={ele.banner} alt='' className='w-100 rounded' />
											</a>
										</Carousel.Item>
									)
								})}								
							</Carousel>
						</div>
					)}
				</>
			)}
			{process.env.NEXT_PUBLIC_SITE_NAME as string === 'ntuc' &&
				<footer style={{ backgroundColor: '#eee' }}>
					<Container className='py-3'>
						<Row>
							<Col>
								<small className='text-dark'>Copyright © 2022 <a href='https://findjobs.com.sg' className='text-dark'>Findjobs Pte Ltd.</a></small>
							</Col>
							<Col style={{ textAlign: 'right' }}>
								<small><a href='https://findjobs.com.sg/privacy-policy' className='text-dark'>Privacy Policy</a> | <a href='https://findjobs.com.sg/terms-of-use' className='text-dark'>Terms of Use</a></small>
							</Col>
						</Row>
					</Container>
				</footer>
			}
		</>
	)

}

export default Footer