import {
	Button
} from 'react-bootstrap'
import styles from './FindjobsButton.module.scss'

const FindjobsButton = ({ text, type, ...rest}) => {
	return (
		<>
			<Button
				className={ type === 'outline' ? styles.outline : styles.regular }
				{...rest}
			>
				{text}
			</Button>
		</>
	)
}

export default FindjobsButton