import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Nav from 'react-bootstrap/Nav'
// import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styles from '../styles/Navbar.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faShare } from '@fortawesome/free-solid-svg-icons'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import { Card } from 'react-bootstrap'

import ShareButton from '../components/ShareButton'

const NavBar = () => {
	const router = useRouter()
	const [token, setToken] = useState<string>('')
	const { t } = useTranslation()
	const [show, setShow] = useState(false)
	const [collapse, setCollapse] = useState(false)
	const handleSelectLanguage = (lang) => {
		localStorage.setItem('language', lang)
		router.push(router.asPath, router.asPath, { locale: lang })
	}

	// '/job-basket/saved/[uuid]'
	const [showShare, setShowShare] = useState(false)

	useEffect(() => {
		// open modal when user comes to the landing page for the first time
		const selectedLanguage = localStorage.getItem('language')
		if (selectedLanguage === null && router.pathname === '/') {
			// setShow(true)
		}
		// check if user is not logged in(On protected route)
		const authenticatedArr = ['/applications', '/profile', '/job-alert']
		const noAuthRouting = () => {
			const token = localStorage.getItem('token')
			if (token === null && authenticatedArr.includes(router.pathname)) {
				if (typeof window !== 'undefined') {
					router.push('/login').catch(() => null)
				}
			}
			// render logged in navbar
			const temp: string = localStorage.getItem('token') as string
			setToken(temp)
		}
		noAuthRouting()
	}, [router, router.isReady, router.pathname])


	const landingPageRoutes = ['/', '/app', '/employer', '/jobs', '/about-us']
	
	const jobPosterPages = ['/job-posters', '/job-posters/[id]', '/job-basket/saved/[uuid]'] // router.pathname

	return (
		<>
			<Navbar expand='lg' bg='light' className={styles.navbar} expanded={collapse}>
				<Container>
					{!jobPosterPages.includes(router.pathname) && (
						<Navbar.Brand
							href={`/${router.locale}`}
							className={styles.brand}
						>
							<Image src={process.env.NEXT_PUBLIC_SITE_LOGO} alt='' width='150' />
						</Navbar.Brand>
					)}
					<Navbar.Toggle aria-controls='basic-navbar-nav' onClick={() => setCollapse(collapse ? false : true)} />
					<Navbar.Collapse id='basic-navbar-nav' className='justify-content-end'>
						<Nav style={{ alignItems: 'center' }}>

							{/* Landing pages */}
							{(landingPageRoutes.includes(router.asPath)) &&
								<>
									<Link href='/jobs' passHref>
										<Nav.Link onClick={() => setCollapse(false)} className={router.pathname == '/jobs' ? 'active' : ''}>{t('header.jobs')}</Nav.Link>
									</Link>
									<Link href='/employer' passHref>
										<Nav.Link onClick={() => setCollapse(false)} className={router.pathname == '/employer' ? 'active' : ''}>{t('root.employer')}</Nav.Link>
									</Link>
									<Link href='/app' passHref>
										<Nav.Link onClick={() => setCollapse(false)} className={router.pathname == '/app' ? 'active' : ''}>{t('footer.download_app')}</Nav.Link>
									</Link>
									<Link href='/about-us' passHref>
										<Nav.Link onClick={() => setCollapse(false)} className={router.pathname == '/about-us' ? 'active' : ''}>{t('header.about_us')}</Nav.Link>
									</Link>
								</>}

							{/* Browsing jobs and employers */}
							{(!landingPageRoutes.includes(router.asPath) && !jobPosterPages.includes(router.pathname)) &&
								<>
									<Link href='/browse-jobs' passHref>
										<Nav.Link onClick={() => setCollapse(false)} className={router.pathname == '/browse-jobs' ? 'active' : ''}>{t('header.browse_jobs')}</Nav.Link>
									</Link>
									<Nav.Link
										onClick={() => {
											setCollapse(false)
											window.location.href= '/job-posters'
										}} 
										className={router.pathname == '/job-posters' ? 'active' : ''}
									>
										Job Posters</Nav.Link>
									<Link href='/browse-employers' passHref>
										<Nav.Link onClick={() => setCollapse(false)} className={router.pathname == '/browse-employers' ? 'active' : ''}>{t('root.browse_employers')}</Nav.Link>
									</Link>
								</>}

							{/* My Applicaitons and My Profile */}
							{(!landingPageRoutes.includes(router.asPath) && !jobPosterPages.includes(router.pathname)) &&
								<>
									{token !== null && <Link href='/applications' passHref>
										<Nav.Link onClick={() => setCollapse(false)} className={router.pathname == '/applications' ? 'active' : ''}>{t('header.applied')}</Nav.Link>
									</Link>}
									{token !== null && <Link href='/profile' passHref>
										<Nav.Link onClick={() => setCollapse(false)} className={router.pathname == '/profile' ? 'active' : ''}>{t('header.profile')}</Nav.Link>
									</Link>}
									{token !== null && <Link href='/job-alert' passHref>
										<Nav.Link onClick={() => setCollapse(false)} className={router.pathname == '/job-alert' ? 'active' : ''}>{t('job_alert.Job Alert')}</Nav.Link>
									</Link>}

									{/* Login and Logout */}
									{token === null && <Link href='/login' passHref>
										<Nav.Link onClick={() => setCollapse(false)} className={styles.login}>{t('login.login')}</Nav.Link>
									</Link>}
									{token !== null && <Link href='/logout' passHref>
										<Nav.Link onClick={() => setCollapse(false)} href='/logout' className={styles.login}>{t('header.logout')}</Nav.Link>
									</Link>}
								</>
							}

							{router.pathname === '/job-basket/saved/[uuid]' && (
								<>
									<Nav.Link
										onClick={() => {
											setCollapse(false)
											window.location.href= '/job-posters'
										}}
										// className={router.pathname === '/job-posters' ? 'active' : ''}
									>Job Posters</Nav.Link>
									&nbsp;&nbsp;&nbsp;
									<Nav.Link
										className='btn btn-primary text-white fw-bolder p-2'
										onClick={() => setShowShare(true)}
									>
										<FontAwesomeIcon icon={faShare} /> Share job basket
									</Nav.Link>
								</>
							)}

							{/* <Button variant='outline-*' onClick={() => setShow(true)}>
								<FontAwesomeIcon icon={faGlobe} className='text-primary' /> &nbsp;
								{router?.locale?.toLocaleUpperCase()}
							</Button> */}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<Modal centered show={show} onHide={() => setShow(false)}>
				<div className='text-end mt-3 me-3'>
					<FontAwesomeIcon icon={faTimes} onClick={() => setShow(false)} role="button" />
				</div>
				<Modal.Body className='pt-4 px-5'>
					<h3 className='text-center'>{t('select_lng')}</h3>
					<br />
					<Row className='text-center flex-column flex-md-row'>
						<Col xs={12} md={4} className='mb-4'>
							<Card className={`${styles.createNewJobLanguage}`} onClick={() => { handleSelectLanguage('en'); setShow(false) }}>
								<Card.Body className='d-flex flex-row flex-md-column align-items-center'>
									<Image src='/img/findjobs_en_app_logo.svg' alt='logoEnglish' className={styles.languageIcon} />
									<p className='my-0 mt-md-2'>English</p>
								</Card.Body>
							</Card>
						</Col>
						<Col xs={12} md={4} className='mb-4'>
							<Card className={styles.createNewJobLanguage} onClick={() => { handleSelectLanguage('cn'); setShow(false) }}>
								<Card.Body className='d-flex flex-row flex-md-column align-items-center'>
									<Image src='/img/findjobs_cn_app_logo.svg' alt='logoChinese' className={styles.languageIcon} />
									<p className='my-0 mt-md-2'>中文</p>
								</Card.Body>
							</Card>
						</Col>
						<Col xs={12} md={4} className='mb-4'>
							<Card className={styles.createNewJobLanguage} onClick={() => { handleSelectLanguage('my'); setShow(false) }}>
								<Card.Body className='d-flex flex-row flex-md-column align-items-center'>
									<Image src='/img/findjobs_my_app_logo.svg' alt='logoMalay' className={styles.languageIcon} />
									<p className='my-0 mt-md-2'>Bahasa Melayu</p>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>

			<Modal show={showShare} onHide={() => setShowShare(false)} size='lg'>
				<Modal.Body className='text-center'>
					<p className='fw-bolder mt-3'>Share job basket to friends</p>
					<div className={styles.shareBtn} >
						<ShareButton url={`${process.env.NEXT_PUBLIC_DOMAIN}/job-basket/saved/${router.query.uuid}`} />
					</div>
					<br />
				</Modal.Body>
			</Modal>
		</>
	)
}


export default NavBar
