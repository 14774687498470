import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faLinkedin, faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import styles from '../styles/ShareButton.module.scss'

interface ShareButtonProps {
	title?: string
	url: string 
}

const ShareButton: React.FC<ShareButtonProps> = ({ title, url }) => {
	console.log(typeof title !== 'undefined')
	console.log(`whatsapp://send?text=${typeof title !== 'undefined' ? title : ''} ${url}`)

	const emailLink = `mailto:?subject=I wanted you to see this job&body=Check out this ${url}`
	const whatsappLink = `whatsapp://send?text=${typeof title !== 'undefined' ? title : ''} ${url}`
	const telegramLink = `https://t.me/share/url?url=${url}`
	const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${url}`
	const linkedinLink = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
  
	return (
		<div className={styles.shareBtnContainer}>
			<a href={emailLink} title={title}>
				<Button variant='outline-primary'>
					<FontAwesomeIcon icon={faEnvelope} />
				</Button>
				<br />
				<small>Email</small>
			</a>

			<a href={whatsappLink} data-action="share/whatsapp/share" target="_blank" rel="noreferrer">
				<Button variant='outline-primary'>
					<FontAwesomeIcon icon={faWhatsapp} />
				</Button>
				<br />
				<small>Whatsapp</small>
			</a>

			<a href={telegramLink} target="_blank" rel="noreferrer">
				<Button variant='outline-primary'>
					<FontAwesomeIcon icon={faTelegram} />
				</Button>
				<br />
				<small>Telegram</small>
			</a>

			<a href={facebookLink} target="_blank" title={title} rel="noreferrer">
				<Button variant='outline-primary'>
					<FontAwesomeIcon icon={faFacebook} />
				</Button>
				<br />
				<small>Facebook</small>
			</a>

			<a href={linkedinLink} target="_blank" rel="noreferrer">
				<Button variant='outline-primary'>
					<FontAwesomeIcon icon={faLinkedin} />
				</Button>
				<br />
				<small>Linkedin</small>
			</a>
		</div>
	)
}

export default ShareButton